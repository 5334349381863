import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import CategoriesSide from "./subs/CategoriesSide";
import ProgramsSide from "./subs/ProgramsSide";

class LeftSide extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const path = this.props.location.pathname;

    function RenderRightComponent() {
      if (
        path.split("/")[1] === "routines" ||
        path === "/" ||
        path.split("/")[1] === "search" ||
        path === "/cart/thank-you"
      ) {
        return <CategoriesSide />;
      }
      if (path.split("/")[1] === "programs" || path === "/") {
        return <ProgramsSide />;
      }
    }

    return (
      <div className={"left-sidebar"}>
        {RenderRightComponent()}
        <div className={"copyright"}>
          <div className="social-media">
            <a href={"https://twitter.com/liveofffitness"} target={"_blank"}>
              <img
                alt="twitter"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgogICAgIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIKICAgICB2aWV3Qm94PSIwIDAgMTYgMTYiCiAgICAgc3R5bGU9ImZpbGw6IzExMTsiPjxnIGlkPSJzdXJmYWNlMSI+PHBhdGggc3R5bGU9IiAiIGQ9Ik0gMTUgMy4yOTY4NzUgQyAxNC40NzY1NjMgMy41MjM0MzggMTMuOTQ5MjE5IDMuNjkxNDA2IDEzLjM2NzE4OCAzLjc0NjA5NCBDIDEzLjk0OTIxOSAzLjQxMDE1NiAxNC40MTc5NjkgMi44NDM3NSAxNC42NDg0MzggMi4yMjY1NjMgQyAxNC4wNjY0MDYgMi41NjI1IDEzLjQ4NDM3NSAyLjc4OTA2MyAxMi44NDM3NSAyLjkwMjM0NCBDIDEyLjI1NzgxMyAyLjMzOTg0NCAxMS41IDIgMTAuNjgzNTk0IDIgQyA5LjEwOTM3NSAyIDcuODI0MjE5IDMuMjQyMTg4IDcuODI0MjE5IDQuNzY1NjI1IEMgNy44MjQyMTkgNC45ODgyODEgNy44MjQyMTkgNS4yMTQ4NDQgNy44ODI4MTMgNS4zODY3MTkgQyA0Ljg3NSA1LjM4NjcxOSAyLjgxMjUgMy42OTE0MDYgMS40MTQwNjMgMiBDIDEuMTIxMDk0IDIuMzk0NTMxIDEuMDAzOTA2IDIuOTAyMzQ0IDEuMDAzOTA2IDMuNDEwMTU2IEMgMS4wMDM5MDYgNC4zNjcxODggMS41MzEyNSA1LjIxNDg0NCAyLjI4OTA2MyA1LjcyMjY1NiBDIDEuODIwMzEzIDUuNjY3OTY5IDEuMzU1NDY5IDUuNTU0Njg4IDEuMDAzOTA2IDUuMzg2NzE5IEMgMS4wMDM5MDYgNS4zODY3MTkgMS4wMDM5MDYgNS4zODY3MTkgMS4wMDM5MDYgNS40NDE0MDYgQyAxLjAwMzkwNiA2Ljc5Njg3NSAxLjk5NjA5NCA3LjkyMTg3NSAzLjI4MTI1IDguMTQ4NDM4IEMgMy4wNDY4NzUgOC4yMDMxMjUgMi44MTI1IDguMjYxNzE5IDIuNTE5NTMxIDguMjYxNzE5IEMgMi4zNDc2NTYgOC4yNjE3MTkgMi4xNzE4NzUgOC4yNjE3MTkgMS45OTYwOTQgOC4yMDcwMzEgQyAyLjM0NzY1NiA5LjMzNTkzOCAzLjk3NjU2MyAxMC42MzI4MTMgNS4yNTc4MTMgMTAuNjMyODEzIEMgNC4yNjU2MjUgMTEuMzYzMjgxIDMuMzQzNzUgMTIgMS41IDEyIEMgMS4yNjU2MjUgMTIgMS40NTMxMjUgMTIgMSAxMiBDIDIuMjgxMjUgMTIuNzg5MDYzIDMuODAwNzgxIDEzIDUuMzc1IDEzIEMgMTAuNjgzNTk0IDEzIDEzLjU0Mjk2OSA4Ljc2OTUzMSAxMy41NDI5NjkgNS4xMDE1NjMgQyAxMy41NDI5NjkgNC45ODgyODEgMTMuNTQyOTY5IDQuODc4OTA2IDEzLjU0Mjk2OSA0Ljc2NTYyNSBDIDE0LjEyNSA0LjM2NzE4OCAxNC41OTM3NSAzLjg2MzI4MSAxNSAzLjI5Njg3NSAiPjwvcGF0aD48L2c+PC9zdmc+"
              />
            </a>
            <a href={"https://instagram.com/liveofffitness"} target={"_blank"}>
              <img
                alt="instagram"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgogICAgIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIKICAgICB2aWV3Qm94PSIwIDAgMTYgMTYiCiAgICAgc3R5bGU9ImZpbGw6IzExMTsiPjxnIGlkPSJzdXJmYWNlMSI+PHBhdGggc3R5bGU9IiAiIGQ9Ik0gNC43NzM0MzggMSBDIDIuNjk1MzEzIDEgMSAyLjY5NTMxMyAxIDQuNzczNDM4IEwgMSAxMC4yMzA0NjkgQyAxIDEyLjMwNDY4OCAyLjY5NTMxMyAxNCA0Ljc3MzQzOCAxNCBMIDEwLjIzMDQ2OSAxNCBDIDEyLjMwNDY4OCAxNCAxNCAxMi4zMDQ2ODggMTQgMTAuMjI2NTYzIEwgMTQgNC43NzM0MzggQyAxNCAyLjY5NTMxMyAxMi4zMDQ2ODggMSAxMC4yMjY1NjMgMSBaIE0gNC43NzM0MzggMiBMIDEwLjIyNjU2MyAyIEMgMTEuNzY1NjI1IDIgMTMgMy4yMzQzNzUgMTMgNC43NzM0MzggTCAxMyAxMC4yMjY1NjMgQyAxMyAxMS43NjU2MjUgMTEuNzY1NjI1IDEzIDEwLjIzMDQ2OSAxMyBMIDQuNzczNDM4IDEzIEMgMy4yMzQzNzUgMTMgMiAxMS43NjU2MjUgMiAxMC4yMzA0NjkgTCAyIDQuNzczNDM4IEMgMiAzLjIzNDM3NSAzLjIzNDM3NSAyIDQuNzczNDM4IDIgWiBNIDExLjUgMyBDIDExLjIyMjY1NiAzIDExIDMuMjIyNjU2IDExIDMuNSBDIDExIDMuNzc3MzQ0IDExLjIyMjY1NiA0IDExLjUgNCBDIDExLjc3NzM0NCA0IDEyIDMuNzc3MzQ0IDEyIDMuNSBDIDEyIDMuMjIyNjU2IDExLjc3NzM0NCAzIDExLjUgMyBaIE0gNy41IDQgQyA1LjU3NDIxOSA0IDQgNS41NzQyMTkgNCA3LjUgQyA0IDkuNDI1NzgxIDUuNTc0MjE5IDExIDcuNSAxMSBDIDkuNDI1NzgxIDExIDExIDkuNDI1NzgxIDExIDcuNSBDIDExIDUuNTc0MjE5IDkuNDI1NzgxIDQgNy41IDQgWiBNIDcuNSA1IEMgOC44ODY3MTkgNSAxMCA2LjExMzI4MSAxMCA3LjUgQyAxMCA4Ljg4NjcxOSA4Ljg4NjcxOSAxMCA3LjUgMTAgQyA2LjExMzI4MSAxMCA1IDguODg2NzE5IDUgNy41IEMgNSA2LjExMzI4MSA2LjExMzI4MSA1IDcuNSA1IFogIj48L3BhdGg+PC9nPjwvc3ZnPg=="
              />
            </a>
            <a href={'https://facebook.com/liveofffitness'} target={'_blank'}>
              <img
                alt="facebook"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMTYiIGhlaWdodD0iMTYiCnZpZXdCb3g9IjAgMCAxNiAxNiIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBkPSJNIDcuNSAxIEMgMy45MTYwNzE0IDEgMSAzLjkxNjA3MTQgMSA3LjUgQyAxIDExLjA4MzkyOSAzLjkxNjA3MTQgMTQgNy41IDE0IEMgMTEuMDgzOTI5IDE0IDE0IDExLjA4MzkyOSAxNCA3LjUgQyAxNCAzLjkxNjA3MTQgMTEuMDgzOTI5IDEgNy41IDEgeiBNIDcuNSAyIEMgMTAuNTQzNDg4IDIgMTMgNC40NTY1MTE2IDEzIDcuNSBDIDEzIDEwLjI2NjMzMyAxMC45Njc1NzEgMTIuNTQxMDI0IDguMzEyNSAxMi45MzM1OTQgTCA4LjMxMjUgOS4wODk4NDM4IEwgOS44NjUyMzQ0IDkuMDg5ODQzOCBMIDEwLjEwOTM3NSA3LjUxMzY3MTkgTCA4LjMxMjUgNy41MTM2NzE5IEwgOC4zMTI1IDYuNjUwMzkwNiBDIDguMzEyNSA1Ljk5NTM5MDYgOC41MjU2NzE5IDUuNDE0MDYyNSA5LjEzODY3MTkgNS40MTQwNjI1IEwgMTAuMTIzMDQ3IDUuNDE0MDYyNSBMIDEwLjEyMzA0NyA0LjAzNzEwOTQgQyA5Ljk1MDA0NjkgNC4wMTQxMDk0IDkuNTg0NTc4MSAzLjk2Mjg5MDYgOC44OTI1NzgxIDMuOTYyODkwNiBDIDcuNDQ4NTc4MSAzLjk2Mjg5MDYgNi42MDE1NjI1IDQuNzI1ODkwNiA2LjYwMTU2MjUgNi40NjI4OTA2IEwgNi42MDE1NjI1IDcuNTExNzE4OCBMIDUuMTE3MTg3NSA3LjUxMTcxODggTCA1LjExNzE4NzUgOS4wODk4NDM4IEwgNi42MDM1MTU2IDkuMDg5ODQzOCBMIDYuNjAzNTE1NiAxMi45MTk5MjIgQyAzLjk4OTc4NjggMTIuNDkyMTE4IDIgMTAuMjM3MDY2IDIgNy41IEMgMiA0LjQ1NjUxMTYgNC40NTY1MTE2IDIgNy41IDIgeiI+PC9wYXRoPjwvc3ZnPg=="
              />
            </a>
            <a href={'https://www.linkedin.com/company/liveoff-fitness'} target={'_blank'}>
              <img 
                alt="linkedin"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAChUlEQVR4nO2avW4TQRRGDwgkilSxhJQfyqSDHokKEEoRngBqupSQisgPwhMkCKWHjgYJyBOARIOgwIlsI5ECyxSzltjReu7und2ZWXmOdIv13pn55vPuXXtnIJPJZJYzAIbAZ2AKzBONaaFxWGhuhcfARQKTaxoXhXbvyc8SmIw2ZsC+dvID+vnN23EOrGsMGFodXQLPgU1NZ4HYxGi8pKz9SNPZmdXJi3Y0BuGQsvZPmk7sar/RlroAbFHWPpYaXKn4bF4jJ2Ua6b+qHGQbOAEmRbwBdpV9JYddTW22gVFF3qg4FxtJv3cHJxU5izjWDNgynRswqcipXXQC0MgAbQ1wDd4rNAa8dZx7pxWSEtIltMvyIrgTSKOLzmsAmGp/jLnnx8Br0pg8NDQg/xDqUEgvyAbEFhCba4o2UmGx77m6+TeBZ8AD4Bbmn90f4CfwDTjF/Of41USsBqmKSm9iNPmPMG9wpNwxxiRXYev8Mdi2AfeAvzXy/o+XHvpFQhvwtUaOHTPgoVK/SGgDtPFeqV+kLwbMgdsK/SX6/hi879tBKgacAXvAGubRvAO8qtHubhdiYhTBtSVaToW2HxT6RUIbcODQsie0/a7QLxLagDsOLRtC24lCf4kUasAPx7mR0PaG7+ApGPDbcW4mtL3uO7jmhYh0WfnmS3qajpdfiLjIBsQWEJtsQGwBsckGxBYQm2xAbAGxWXkDNOsCTdcKfdcWO12brLoCptZxyhskbew9SuKOlSoDvljHT9VywvPEOrbnUouqrbKHmKWqVNnCaGxlq+yAestUqYd6szSs+Hb5Bfv080o4b2PyC9Yx99FH3PsDY8cEszP8CI/LPpPJZDKZzGrxD7QZH9MJhdroAAAAAElFTkSuQmCC" />
              </a>
          </div>
          <Link to="/download-app" className={"extra"}>
            Download our mobile app
          </Link>
          <p>&copy; {new Date().getFullYear()} LiveOff</p>
        </div>
      </div>
    );
  }
}

export default withRouter(LeftSide);
