import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import $ from "jquery";

class CategoriesSide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cats: []
        };
        this.getCategories = this.getCategories.bind(this);
    }

    componentWillMount() {
        this.getCategories();
    }

    getCategories() {
        let self = this;

        $.ajax({
            type: 'POST',
            url: 'https://liveofffitness.com/php/getAllCategories.php'
        })
            .done(function (data) {
                let d = JSON.parse(data);
                self.setState({
                    cats: d[0]
                });
            })
            .fail(function (err) {
                console.log(err);
            });
    }

    render() {
        let { cats } = this.state,
            c = [];

        function RenderCategoriesList() {
            for (let i = 0; i < cats.length; i++) {
                let catURL = cats[i].replace(/\s+/g, '-').toLowerCase();
                c.push(<NavLink exact activeClassName={'activeSide'} to={'/routines/categories/' + catURL} key={'rC-' + i} className={'rC-cat'}>{cats[i]}</NavLink>);
            }
            return c;
        }

        return (
            <div className={'rC'}>
                <h3>Categories</h3>
                {RenderCategoriesList()}
                <NavLink exact activeClassName={'activeSide'} to={'/routines/all'} key={'rC-all'} className={'rC-cat'}>All</NavLink>
            </div>
        );
    }
}

export default withRouter(CategoriesSide);

