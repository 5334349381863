import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Component, Suspense, lazy } from 'react';
import TopBar from './TopBar';
import LeftSide from './LeftSide/LeftSide';
import MobileMenu from './MobileMenu';
export const Landing = lazy(() => import('./Landing/Landing'));
export const RoutinesHome = lazy(() => import('./Routines/RoutinesHome'));
export const RoutinesAll = lazy(() => import('./Routines/RoutinesAll'));
export const Routine = lazy(() => import('./Routines/Routine'));
export const Category = lazy(() => import('./Routines/Category'));
export const Search = lazy(() => import('./Search/Search'));
export const Programs = lazy(() => import('./Programs/Programs'));
export const ProgramsGoal = lazy(() => import('./Programs/ProgramsGoal'));
export const ProgramSelect = lazy(() => import('./Programs/ProgramSelect'));
export const Profile = lazy(() => import('./Profile/Profile'));
export const Join = lazy(() => import('../Join/Join'));
export const Schedule = lazy(() => import('./Schedule/Schedule'));
export const Onboarding = lazy(() => import('../Onboarding/Onboarding'));
export const Player = lazy(() => import('./Player/Player'));
export const RoutineRecommended = lazy(() => import('./Routines/RoutineRecommended'));
export const RoutineTrending = lazy(() => import('./Routines/RoutineTrending'));
export const PrivacyPolicy = lazy(() => import('./Policies/PrivacyPolicy'));
export const TermsConditions = lazy(() => import('./Policies/TermsConditions'));
export const RefundPolicy = lazy(() => import('./Policies/RefundPolicy'));
export const DownloadApp = lazy(() => import('./DownloadApp'));
export const Shop = lazy(() => import('./Shop'));
export const ForgottenPassword = lazy(() => import('./ForgottenPassword'));
export const ResetPassword = lazy(() => import('./ResetPassword'));
export const Cart = lazy(() => import('./Cart/Cart'));
export const CartThankYou = lazy(() => import('./Cart/CartThankYou'));
export const PageNotFound = lazy(() => import('./PageNotFound'));

class Home extends Component {
  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset >= 50) {
      document.querySelector('.top-bar').classList.add('scrolled');
    } else {
      document.querySelector('.top-bar').classList.remove('scrolled');
    }
  }

  render() {
    return (
      <Router>
        <Suspense fallback={<div className="loader">
            <div className="line" />
            <div className="line" />
            <div className="line" />
            <div className="line" />
          </div>}>
          <div className="app-container">
            <div className="header-container">
              <TopBar />
            </div>
            <MobileMenu />
            <div className={'app-max'}>
              <div className="main-content">
                <div className="content">
                  <LeftSide />
                  <Switch>

                    <Route exact path={'/'} component={Landing} />
                    <Route exact path={'/routines'} component={RoutinesHome} />
                    <Route
                      path={'/routines/recommended'}
                      component={RoutineRecommended}
                    />
                    <Route
                      path={'/routines/trending'}
                      component={RoutineTrending}
                    />
                    <Route path={'/routines/all'} component={RoutinesAll} />
                    <Route path={'/routines/routine/:name'} component={Routine} />
                    <Route
                      path={'/routines/categories/:name'}
                      component={Category}
                    />
                    <Route exact path={'/cart'} component={Cart} />
                    <Route path={'/cart/thank-you'} component={CartThankYou} />
                    <Route path={'/search'} component={Search} />
                    <Route exact path={'/programs'} component={Programs} />
                    <Route
                      exact
                      path={'/programs/:type'}
                      component={ProgramSelect}
                    />
                    <Route
                      path={'/programs/goal/:goal'}
                      component={props => <ProgramsGoal {...props} />}
                    />
                    <Route path={'/settings'} component={Profile} />
                    <Route path={'/schedule'} component={Schedule} />
                    <Route path={'/join'} component={Join} />
                    <Route path={'/on-boarding'} component={Onboarding} />
                    <Route exact path={'/player'} component={Player} />
                    <Route
                      exact
                      path={'/player/:type'}
                      render={props => <Player {...props} />}
                    />
                    <Route
                      path={'/player/:type/:name'}
                      render={props => <Player {...props} />}
                    />
                    <Route
                      path={'/terms-conditions'}
                      component={TermsConditions}
                    />
                    <Route path={'/privacy-policy'} component={PrivacyPolicy} />
                    <Route path={'/refund-policy'} component={RefundPolicy} />
                    <Route path={'/download-app'} component={DownloadApp} />
                    <Route path={'/shop'} component={Shop} />
                    <Route path={'/forgot-password'} component={ForgottenPassword} />
                    <Route path={'/reset-password/:id'} component={ResetPassword} />
                    <Route component={PageNotFound} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </Suspense>
      </Router>
    );
  }
}

export default Home;
