import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import $ from "jquery";

class CategoriesSide extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentWillMount() {
    }

    render() {

        return (
            <div className={'rC'}>
                <h3>Programs</h3>
                <NavLink exact activeClassName={'activeSide'} to={'/programs/bodybuilding'} className={'rC-cat'}>Bodybuilding</NavLink>
                <NavLink exact activeClassName={'activeSide'} to={'/programs/body-composition'} className={'rC-cat'}>Body Composition</NavLink>
                <NavLink exact activeClassName={'activeSide'} to={'/programs/conditioning'} className={'rC-cat'}>Conditioning</NavLink>
                <NavLink exact activeClassName={'activeSide'} to={'/programs/custom'} className={'rC-cat'}>Custom</NavLink>
                <NavLink exact activeClassName={'activeSide'} to={'/programs/endurance'} className={'rC-cat'}>Endurance</NavLink>
                <NavLink exact activeClassName={'activeSide'} to={'/programs/hypertrophy'} className={'rC-cat'}>Hypertrophy</NavLink>
                <NavLink exact activeClassName={'activeSide'} to={'/programs/strength'} className={'rC-cat'}>Strength</NavLink>
                <NavLink exact activeClassName={'activeSide'} to={'/programs/toning'} className={'rC-cat'}>Toning</NavLink>
            </div>
        );
    }
}

export default withRouter(CategoriesSide);

