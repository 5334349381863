import React, { Component } from "react";
import "./styles/App.sass";
import Home from "./routes/App/Home.js";

class App extends Component {
  state = {
    ready: true
  };

  componentWillMount() {
    if (!localStorage.getItem("username")) {
      localStorage.setItem("username", "guest");
    }
  }

  componentDidMount() {
    const self = this;
    setTimeout(function() {
      self.setState({
        ready: true
      });
    }, 1500);
  }

  render() {
    let { ready } = this.state;

    function RenderContent() {
      if (ready) {
        return (
            <Home />
        );
      } else {
        return (
          <div className="loader">
            <div className="line" />
            <div className="line" />
            <div className="line" />
            <div className="line" />
          </div>
        );
      }
    }

    return RenderContent();
  }
}

export default App;
