import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import $ from 'jquery';

class TopBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profilePic: '',
      points: 0,
      currPath: this.props.location.pathname,
      guest: false,
      premium: 0
    };
  }

  componentDidMount() {
    this.getProfilePic();
    if (localStorage.getItem('getstarted') === '1') {
      this.props.history.push('/on-boarding');
    }
    let self = this;
    setInterval(() => {
      // Update cart
      if (!this.state.guest) {
        if (localStorage.getItem('lo-cart-program')) {
          document.querySelector('.cartBtn').classList.add('notEmpty');
        } else {
          document.querySelector('.cartBtn').classList.remove('notEmpty');
        }
      }

      // Update top buttons
      if (
        localStorage.getItem('username') === 'guest' ||
        localStorage.getItem('username') === '' ||
        localStorage.getItem('username') === undefined
      ) {
        self.setState({ guest: true });
      } else {
        self.setState({ guest: false });
      }
    }, 1000);
  }

  componentDidUpdate() {
    let self = this;
    if (self.state.currPath !== self.props.location.pathname) {
      self.setState(
        {
          currPath: self.props.location.pathname
        },
        self.getProfilePic()
      );
    }
  }

  getProfilePic = () => {
    let self = this,
      data = JSON.stringify({
        username: localStorage.getItem('username')
      });

    $.ajax({
      type: 'POST',
      url: 'https://liveofffitness.com/php/getUserInfo.php',
      data: data
    })
      .done(function(data) {
        let d = JSON.parse(data);
        if (d !== null) {
          localStorage.setItem('premium', d.premium);
          self.setState({
            profilePic: d.profile_pic,
            premium: d.premium
          });
        }
      })
      .fail(function(err) {
        console.log(err);
      });
  };

  toRegistration = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('lo_name');
    this.props.history.push('/join');
  };

  render() {
    let profile_link = '/settings/',
      { profilePic, guest } = this.state,
      self = this;

    if (
      profilePic ===
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgogICAgIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIKICAgICB2aWV3Qm94PSIwIDAgMjYgMjYiCiAgICAgc3R5bGU9ImZpbGw6IzU1NDRmZjsiPjxnIGlkPSJzdXJmYWNlMSI+PHBhdGggc3R5bGU9IiAiIGQ9Ik0gMTMgMCBDIDkuNjk5MjE5IDAgNyAyLjEwMTU2MyA3IDYgQyA3IDguNjA5Mzc1IDguMjE0ODQ0IDExLjMxMjUgMTAgMTIuODEyNSBMIDEwIDE0LjE4NzUgQyAxMCAxNC43ODkwNjMgOS41OTM3NSAxNS4zMDQ2ODggOS4wOTM3NSAxNS40MDYyNSBDIDUuMTk1MzEzIDE2LjYwNTQ2OSAyIDE5LjE4NzUgMiAyMC42ODc1IEwgMiAyMi41IEMgMiAyNC4zOTg0MzggNi44OTg0MzggMjYgMTMgMjYgQyAxOS4xMDE1NjMgMjYgMjQgMjQuMzk4NDM4IDI0IDIyLjUgTCAyNCAyMC42ODc1IEMgMjQgMTkuMjg5MDYzIDIwLjkwNjI1IDE2LjYwNTQ2OSAxNi45MDYyNSAxNS40MDYyNSBDIDE2LjQwNjI1IDE1LjMwNDY4OCAxNiAxNC42ODc1IDE2IDE0LjE4NzUgTCAxNiAxMi44MTI1IEMgMTcuNzg1MTU2IDExLjMxMjUgMTkgOC42MDkzNzUgMTkgNiBDIDE5IDIuMTAxNTYzIDE2LjMwMDc4MSAwIDEzIDAgWiAiPjwvcGF0aD48L2c+PC9zdmc+'
    ) {
      $('.profile-img').addClass('guest-profile');
    } else {
      profilePic = decodeURIComponent(profilePic);
      profilePic = profilePic.split('+').join('%20');
      profilePic = profilePic.split(' ').join('%20');
    }

    function RenderIsLoggedIn() {
      if (!guest) {
        return (
          <ul className={'top-right'}>
            <li className="profile-li">
              <Link to={'/search'} className={'searchBtn'}>
                <img
                  alt="search"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgogICAgIHdpZHRoPSI5NiIgaGVpZ2h0PSI5NiIKICAgICB2aWV3Qm94PSIwIDAgMzAgMzAiCiAgICAgc3R5bGU9ImZpbGw6IzExMTsiPiAgICA8cGF0aCBzdHlsZT0ibGluZS1oZWlnaHQ6bm9ybWFsO3RleHQtaW5kZW50OjA7dGV4dC1hbGlnbjpzdGFydDt0ZXh0LWRlY29yYXRpb24tbGluZTpub25lO3RleHQtZGVjb3JhdGlvbi1zdHlsZTpzb2xpZDt0ZXh0LWRlY29yYXRpb24tY29sb3I6IzAwMDt0ZXh0LXRyYW5zZm9ybTpub25lO2Jsb2NrLXByb2dyZXNzaW9uOnRiO2lzb2xhdGlvbjphdXRvO21peC1ibGVuZC1tb2RlOm5vcm1hbCIgZD0iTSAxMyAzIEMgNy40ODg5OTcxIDMgMyA3LjQ4ODk5NzEgMyAxMyBDIDMgMTguNTExMDAzIDcuNDg4OTk3MSAyMyAxMyAyMyBDIDE1LjM5NjUwOCAyMyAxNy41OTczODUgMjIuMTQ4OTg2IDE5LjMyMjI2NiAyMC43MzYzMjggTCAyNS4yOTI5NjkgMjYuNzA3MDMxIEEgMS4wMDAxIDEuMDAwMSAwIDEgMCAyNi43MDcwMzEgMjUuMjkyOTY5IEwgMjAuNzM2MzI4IDE5LjMyMjI2NiBDIDIyLjE0ODk4NiAxNy41OTczODUgMjMgMTUuMzk2NTA4IDIzIDEzIEMgMjMgNy40ODg5OTcxIDE4LjUxMTAwMyAzIDEzIDMgeiBNIDEzIDUgQyAxNy40MzAxMjMgNSAyMSA4LjU2OTg3NzQgMjEgMTMgQyAyMSAxNy40MzAxMjMgMTcuNDMwMTIzIDIxIDEzIDIxIEMgOC41Njk4Nzc0IDIxIDUgMTcuNDMwMTIzIDUgMTMgQyA1IDguNTY5ODc3NCA4LjU2OTg3NzQgNSAxMyA1IHoiIGZvbnQtd2VpZ2h0PSI0MDAiIGZvbnQtZmFtaWx5PSJzYW5zLXNlcmlmIiB3aGl0ZS1zcGFjZT0ibm9ybWFsIiBvdmVyZmxvdz0idmlzaWJsZSI+PC9wYXRoPjwvc3ZnPg=="
                />
              </Link>
            </li>
            <Link to={'/cart'} className={'cartBtn'}>
              <img
                alt="cart"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IgogICAgIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIKICAgICB2aWV3Qm94PSIwIDAgMTYgMTYiCiAgICAgc3R5bGU9ImZpbGw6IzExMTsiPjxnIGlkPSJzdXJmYWNlMSI+PHBhdGggc3R5bGU9IiAiIGQ9Ik0gOCAxIEMgNi4zNTE1NjMgMSA1IDIuMzUxNTYzIDUgNCBMIDMgNCBDIDIuNzM0Mzc1IDQgMi41MTU2MjUgNC4yMDcwMzEgMi41IDQuNDcyNjU2IEwgMiAxMy40NzI2NTYgQyAxLjk5MjE4OCAxMy42MDkzNzUgMi4wNDI5NjkgMTMuNzQ2MDk0IDIuMTM2NzE5IDEzLjg0Mzc1IEMgMi4yMzA0NjkgMTMuOTQ1MzEzIDIuMzYzMjgxIDE0IDIuNSAxNCBMIDEzLjUgMTQgQyAxMy42MzY3MTkgMTQgMTMuNzY5NTMxIDEzLjk0NTMxMyAxMy44NjMyODEgMTMuODQzNzUgQyAxMy45NTcwMzEgMTMuNzQ2MDk0IDE0LjAwNzgxMyAxMy42MDkzNzUgMTQgMTMuNDcyNjU2IEwgMTMuNSA0LjQ3MjY1NiBDIDEzLjQ4NDM3NSA0LjIwNzAzMSAxMy4yNjU2MjUgNCAxMyA0IEwgMTEgNCBDIDExIDIuMzUxNTYzIDkuNjQ4NDM4IDEgOCAxIFogTSA4IDIgQyA5LjEwMTU2MyAyIDEwIDIuODk4NDM4IDEwIDQgTCA2IDQgQyA2IDIuODk4NDM4IDYuODk4NDM4IDIgOCAyIFogTSAzLjQ3MjY1NiA1IEwgNSA1IEwgNSA3IEwgNiA3IEwgNiA1IEwgMTAgNSBMIDEwIDcgTCAxMSA3IEwgMTEgNSBMIDEyLjUyNzM0NCA1IEwgMTIuOTY4NzUgMTMgTCAzLjAzMTI1IDEzIFogIj48L3BhdGg+PC9nPjwvc3ZnPg=="
              />
            </Link>
            <Link to={profile_link}>
              <div
                className="profile-img"
                style={{ backgroundImage: `url(${profilePic})` }}
              />
            </Link>
          </ul>
        );
      } else {
        return (
          <ul>
            <li>
              <div onClick={() => self.toRegistration()}>
                <p className="signup-btn button-raise">Sign Up</p>
              </div>
            </li>
          </ul>
        );
      }
    }

    return (
      <div className="top-bar">
        <div className="left-side">
          <Link to="/" className="logo-container">
            <img
              className="logo"
              alt="liveoff fitness logo"
              src="/assets/logo-01.svg"
            />
          </Link>
        </div>
        <ul className="menu">
          <li className="menu-item">
            <NavLink
              exact
              to="/"
              activeClassName="activeLink"
              className={'link'}
            >
              Home
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink
              to="/routines"
              activeClassName="activeLink"
              className={'link'}
            >
              Routines
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink
              to="/programs"
              activeClassName="activeLink"
              className={'link'}
            >
              Programs
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink
              exact
              to="/schedule"
              activeClassName="activeLink"
              className={'link'}
            >
              Schedule
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink
              exact
              to="/shop"
              activeClassName="activeLink"
              className={'link'}
            >
              Shop
            </NavLink>
          </li>
          <li className="menu-item">
            <a
              href="https://medium.com/@liveofffitness"
              target={'_blank'}
              className={'link'}
            >
              Blog
            </a>
          </li>
          {!guest && (
            <li className="menu-item" id={'menu-player'}>
              <NavLink
                exact
                to="/player"
                activeClassName="activeLink"
                className={'link'}
              >
                Player
              </NavLink>
            </li>
          )}
        </ul>
        {RenderIsLoggedIn()}
      </div>
    );
  }
}

export default withRouter(TopBar);
